import CampaignContainerComponent from "../CampaignContainerComponent";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function CampaignsThumbnailsComponent(props){
    const classes = useStyles();

    return <main className={classes.content}>
        <div className={classes.toolbar} />
        <CampaignContainerComponent />
    </main>
}